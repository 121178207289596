import React from 'react';

import {graphql} from 'gatsby';
import {Box, LayoutGrid, StyledRichContent, SEOWithQuery} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';

import {MembershipAgreementPageQuery} from 'graphql-types';

const {P0, P2} = Typography;

export const query = graphql`
  query MembershipAgreementPage {
    doc: sanityPublicMembershipAgreement(_id: {eq: "publicMembershipAgreement"}) {
      version
      content: _rawContent
      copyright
    }
  }
`;

type MembershipAgreementProps = {
  data: MembershipAgreementPageQuery;
};

export const MembershipAgreement: React.FC<MembershipAgreementProps> = ({data}) => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid>
        <Box gridColumn="1/end">
          <Box textAlign="center" pt="83px" pb="43px" mb="32px">
            <Box width="150px" mb="34px" mx="auto">
              <Icon aria-label={copy.membershipAgreement.ariaLabels.logo} icon={Icons.logo} color="cream" />
            </Box>
            <P0 color="cream" as="h1" mb="21px">
              {copy.membershipAgreement.title}
            </P0>
            {!!data.doc?.version && (
              <P0 color="cream" as="h1" mb="21px">
                {copy.membershipAgreement.versionLabel} {data.doc.version}
              </P0>
            )}
          </Box>
          <StyledRichContent blocks={data.doc?.content} />
          {!!data.doc?.copyright && (
            <>
              <Box as="hr" my="24px" />
              <P2 color="cream" textAlign="center" mb="40px">
                {data.doc.copyright}
              </P2>
            </>
          )}
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={copy.seo.membershipAgreementTitle} noIndex />
    </Box>
  );
};

export default MembershipAgreement;
